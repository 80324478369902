<template>
  <div class="mt-10 fade">
    <v-list dense class="drawer">
      <section v-for="(item, i) in lists" :key="i">
		<section v-for="(list, j) in item.lists" :key="j">
			<v-list-item v-if="j==1 && list.route == 'User My Courses' && !$vuetify.breakpoint.mobile && !$vuetify.breakpoint.sm" 
				class="px-5 li" 
				exact
				:class="$route.meta.learning ? 'navigation_link_background_color navigation_link_color--text':'' "
				:style="mini ? $route.meta.learning : {  background: getlinkBackgroundColor, color: getlinkColor, borderRight: `5px solid ${getlinkColorMini}` } ? '' : $route.meta.learning ? {  background: getlinkBackgroundColor, color: getlinkColor, borderRight: `5px solid ${getlinkColor}`} : ''"
				@click="collapse()"
			>
				<v-list-item-content>
					<v-list-item-title class="roboto f14 d-flex align-center "
					:class="[$route.meta.learning ? 'navigation_link_color--text' : 'secondary-2--text', mini && 'justify-center']" >
						<v-icon v-if="mini" class="px-1" size="20" :color="($route.name === 'User Course Details')  ? 'mini_navigation_icon_color' : 'mini_navigation_inactive_link_color'">
							mdi-book-open-outline
						</v-icon>
						<v-icon v-else class="px-1" size="20" :class="$route.meta.learning ? 'navigation_link_color--text' : 'secondary-2--text'">mdi-book-open-outline</v-icon>
						<div v-if="!mini" class="mx-3 fw500 ">My Courses</div>
					</v-list-item-title>
				</v-list-item-content>
				
			</v-list-item>
			<v-list-group v-else-if="j==1 && list.route == 'User My Courses' && $vuetify.breakpoint.mobile && $vuetify.breakpoint.sm" 
				class="px-1" 
				exact
				:class="$route.meta.learning?'navigation_link_background_color navigation_link_color--text':'secondary-2--text' "
			>
				<template v-slot:activator>
					<v-list-item-content>
						<v-list-item-title class="roboto f14 d-flex align-center" >
							<v-icon class="px-1" size="20" 
								:class="$route.name !== 'User Course Details' ? 'secondary-2--text' : 'navigation_link_color--text'"> mdi-book-open-outline </v-icon>
							<div class="px-3" 
								:class="$route.name !== 'User Course Details' ? 'secondary-2--text' : 'navigation_link_color--text'"> My Courses </div>
						</v-list-item-title>
					</v-list-item-content>
				</template>

				<!-- mobile view -->
				<v-list v-if="$store.getters['usr/coursesGetter'].length>0">
					<v-list-item dense class="pl-12" 
						v-for="item in $store.getters['usr/coursesGetter']" 
						:key="item.id"
						@click="() => {
							collapse()
							$router.push({name: 'User Course Details', params: {id: item.uuid}})
						}"
						:style="$route.path === `/course/${item.uuid}` && { background: getlinkBackgroundColor, color: getlinkColor, borderRight: `5px solid ${getlinkColor} !important`}"
					>
						<v-list-item-content>
							<v-list-item-title class="roboto fw500 f14 d-flex align-center text-wrap"
								:class="$route.path === `/course/${item.uuid}` ? 'navigation_link_color--text' : 'secondary-2--text'">
								{{item.title}}
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<div v-else class="pa-5 roboto fw500 f14 d-flex align-center">No courses found.</div>
			</v-list-group>
			<!-- <v-list-item 
				v-else
				:to="{ name: list.route }"
				exact
				class="px-5 li" 
				:class="mini ? 'white' : $route.name === list.route ? 'li-active' 
				: $route.meta.parent === list.route ? 'li-active' :  ''"
			> -->
			<v-list-item 
				v-if="list.route !== 'User List' && j!=2"
				:to="{ name: list.route }"
				exact
				class="px-5 li" 
				:style="!mini ? [($route.name === list.route || $route.meta.learning || $route.meta.parent === list.route) 
							? { background: getlinkColor, color: getlinkColor, borderRight: `5px solid ${getlinkColor}`}: ''] 
						: [($route.name === list.route || $route.meta.learning || $route.meta.parent === list.route) ? { background: getlinkColorMini, color: getlinkColorMini, borderRight: `5px solid ${getlinkColorMini}` } : '']"
				:class="!mini ? [($route.name === list.route || $route.meta.learning || $route.meta.parent === list.route)? 'navigation_link_color--text' :  'navigation_link_inactive_color--text'] : [($route.name === list.route || $route.meta.learning || $route.meta.parent === list.route) ? 'mini_navigation_link_color--text' :  'mini_navigation_inactive_link_color--text']"
			>
				<v-list-item-content>
					<v-badge
						overlap
						:inline="!mini"
						bordered
						:color="cart.length === 0 ? 'transparent' : 'primary'"
						:content="cart.length"
						:offset-x="!mini ? 25 : 15"
						:style="text"
						:dot="mini"
						v-if="list.route === 'User List'">
						<v-list-item-title class="roboto f14 d-flex align-center"
							:class="[mini && 'justify-center', $route.name === list.route ? 'navigation_text_icon_color--text' : $route.meta.parent === list.route ? 'navigation_text_icon_color--text' : 'navigation_link_inactive_color--text']">
							<v-icon v-if="mini" class="px-1" size="20" :color="($route.name === list.route || $route.meta.parent === list.route) ? 'mini_navigation_icon_color' : 'mini_navigation_inactive_link_color'">{{list.icon}}</v-icon>
							<v-icon v-else class="px-1" size="20" :class="[$route.name === list.route ? 'navigation_text_icon_color--text' : $route.meta.parent === list.route ? 'navigation_text_icon_color--text' : 'navigation_link_inactive_color--text']">{{list.icon}}</v-icon>
							<div v-if="!mini" class="mx-3 fw500 ">{{list.title}}</div>
						</v-list-item-title>
					</v-badge>
					<v-list-item-title class="roboto f14 d-flex align-center" v-else
						:class="[mini && 'justify-center', $route.name === list.route ? 'navigation_text_icon_color--text' : $route.meta.parent === list.route ? 'navigation_text_icon_color--text' : 'navigation_link_inactive_color--text']">
						<v-icon v-if="mini" class="px-1" size="20" :color="($route.name === list.route || $route.meta.parent === list.route) ? 'mini_navigation_icon_color' : 'mini_navigation_inactive_link_color'">{{list.icon}}</v-icon>
						<v-icon v-else class="px-1" size="20" :class="[$route.name === list.route ? 'navigation_text_icon_color--text' : $route.meta.parent === list.route ? 'navigation_text_icon_color--text' : 'navigation_link_inactive_color--text']">{{list.icon}}</v-icon>
						<div v-if="!mini" class="mx-3 fw500 ">{{list.title}} {{  }}</div>
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item 
				v-else-if="j!=2"
				:to="{ name: list.route }"
				exact
				class="px-5 li" 
				:style="!mini ? [($route.name === list.route || $route.meta.learning || $route.meta.parent === list.route) 
							? { background: getlinkColor, color: getlinkColor, borderRight: `5px solid ${getlinkColor}`}: ''] 
						: [($route.name === list.route || $route.meta.learning || $route.meta.parent === list.route) ? { background: getlinkColorMini, color: getlinkColorMini, borderRight: `5px solid ${getlinkColorMini}` } : '']"
				:class="!mini ? [($route.name === list.route || $route.meta.learning || $route.meta.parent === list.route)? 'navigation_link_color--text' :  'navigation_link_inactive_color--text'] : [($route.name === list.route || $route.meta.learning || $route.meta.parent === list.route) ? 'mini_navigation_link_color--text' :  'mini_navigation_inactive_link_color--text']"
			>
				<v-list-item-content>
					<v-badge
						overlap
						:inline="!mini"
						bordered
						:color="cart.length === 0 ? 'transparent' : 'primary'"
						:content="cart.length"
						:offset-x="!mini ? 25 : 15"
						:style="text"
						:dot="mini"
						v-if="list.route === 'User List' && cart.length > 0">
						<v-list-item-title class="roboto f14 d-flex align-center"
							:class="[mini && 'justify-center', $route.name === list.route ? 'navigation_text_icon_color--text' : $route.meta.parent === list.route ? 'navigation_text_icon_color--text' : 'navigation_link_inactive_color--text']">
							<v-icon v-if="mini" class="px-1" size="20" :color="($route.name === list.route || $route.meta.parent === list.route) ? 'mini_navigation_icon_color' : 'mini_navigation_inactive_link_color'">{{list.icon}}</v-icon>
							<v-icon v-else class="px-1" size="20" :class="[$route.name === list.route ? 'navigation_text_icon_color--text' : $route.meta.parent === list.route ? 'navigation_text_icon_color--text' : 'navigation_link_inactive_color--text']">{{list.icon}}</v-icon>
							<div v-if="!mini" class="mx-3 fw500 ">{{list.title}}</div>
						</v-list-item-title>
					</v-badge>
					<v-list-item-title class="roboto f14 d-flex align-center" v-else
						:class="[mini && 'justify-center', $route.name === list.route ? 'navigation_text_icon_color--text' : $route.meta.parent === list.route ? 'navigation_text_icon_color--text' : 'navigation_link_inactive_color--text']">
						<v-icon v-if="mini" class="px-1" size="20" :color="($route.name === list.route || $route.meta.parent === list.route) ? 'mini_navigation_icon_color' : 'mini_navigation_inactive_link_color'">{{list.icon}}</v-icon>
						<v-icon v-else class="px-1" size="20" :class="[$route.name === list.route ? 'navigation_text_icon_color--text' : $route.meta.parent === list.route ? 'navigation_text_icon_color--text' : 'navigation_link_inactive_color--text']">{{list.icon}}</v-icon>
						<div v-if="!mini" class="mx-3 fw500 ">{{list.title}}</div>
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			
		</section>
        
      </section>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ['lists', 'mini'],
	methods: {
		collapse() {
			if(this.$route.name == 'User Assessment') return
			this.$emit('collapse', true)
		}
	},
	watch: {
		$route (to, from){
			if(to.name === 'User Course Details' && from.name === 'User Course Details') {
				this.$emit('collapse', true)
			} else this.$emit('collapse', false)
    	}
	},
	computed: {
		...mapState({
			customization: (state) => state.customization,
			tenant: (state) => state.tenant,
		}),

		...mapState("usr", {
			cart: (state) => state.cart
		}),

		getlinkColor(){
			return this.customization.hasOwnProperty('navigation_text_icon_color') ? this.customization.navigation_text_icon_color ? this.customization.navigation_text_icon_color : '#b52210' : '#b52210'
		},
    
		getlinkColorMini(){
			return this.customization.hasOwnProperty('mini_navigation_link_color') ? this.customization.mini_navigation_link_color ? this.customization.mini_navigation_link_color : '#b52210' : '#b52210'
		},

		getlinkBackgroundColor(){
			return this.customization.hasOwnProperty('mini_navigation_link_color') ? this.customization.mini_navigation_link_color ? this.customization.mini_navigation_link_color.concat('33') : '#b5221033' : '#B5221033'
		},

		getColorByBgColorMini() {
			if(this.customization.mini_navigation_background_color === '#b52210') return 'secondary-2--text'
			return (parseInt(this.customization.mini_navigation_background_color.replace('#', ''), 16) > 0xffffff / 2) ? 'secondary-2' : 'grey';
		}
	}
}
</script>