<template>
  <!-- <v-navigation-drawer 
    :app="!overlap"
    :hide-overlay="!overlap" 
    v-model="drawer" 
    :color="mini ? 'primary' : ''"
    mini-variant-width="80" 
    width="220"
    :mini-variant="mini"
  > -->
  <section>
    <!-- <template v-slot:prepend>
      <v-toolbar flat height="70" color="primary" class="d-flex justify-center">
        <v-toolbar-title >
          <v-avatar tile  width="115" v-if="!mini">
            <v-img :src="require('../assets/images/logo-white.png')"/>
          </v-avatar>
          <v-avatar tile  width="115" v-else >
            <v-img :src="require('../assets/images/favicon-white.png')" max-height="45" max-width="40"/>
          </v-avatar>
        </v-toolbar-title>
        <v-spacer/>
      </v-toolbar>
    </template>
     -->
    <Avatar :user="user" :name="role === 'super-admin' ? `${user.first_name ? user.first_name : role}` : mini ? `${user.first_name ? user.first_name : role}` : `${user.first_name ? user.first_name : role} ${user.last_name ? user.last_name : 'account'}` " :role="role" :mini="mini"/>
    <SuperNav v-if="role==='super-admin'" :lists="lists" :mini="mini"/>    
    <Nav v-if="role==='admin'" :lists="lists" :mini="mini"/>   
    <TeacherNav v-if="role==='instructor'" :lists="lists" :mini="mini"/>   
    <UserNav v-if="role==='user'" :lists="lists" :mini="mini" @collapse="(e)=>{
        this.$emit('collapse', e)
        $store.commit('drawerMutation', e)
      }"/>

    <!-- <template v-slot:append>
      <v-divider/>
      <v-list dense class="drawer">
        <v-list-item exact class="px-7 li " 
        :class="$route.name == `${role.charAt(0).toUpperCase()+ role.slice(1)} Account Settings` ? 'li-active' : $route.meta.parent == `${role.charAt(0).toUpperCase()+ role.slice(1)} Account Settings` ? 'li-active' : ''"
        :to="{name: `${role.charAt(0).toUpperCase()+ role.slice(1)} Account Settings`}" 
        v-if="role != 'admin'">
          <v-list-item-title class="roboto f14 d-flex align-center secondary-1--text">
            <v-icon :size="!mini ? 18 : 20" :color="!mini ? '' : '#b2bec3'">mdi-cog-outline</v-icon>
            <div v-if="!mini" class="ma-2 pt-1 fw500">Account Settings</div>
          </v-list-item-title>
        </v-list-item>

        <v-list-item exact class="px-7 li"  v-if="role =='user'">
          <v-list-item-title class="roboto f14 d-flex align-center secondary-1--text">
            <v-icon :size="!mini ? 18 : 20" :color="!mini ? '' : '#b2bec3'">mdi-help-circle-outline</v-icon>
            <div v-if="!mini" class="ma-2 pt-1 fw500">Help Desk</div>
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="px-7 li" @click="logout">
          <v-list-item-title class="roboto f14 d-flex align-center secondary-1--text">
            <v-icon :size="!mini ? 18 : 20" :color="!mini ? '' : '#b2bec3'">mdi-logout</v-icon>
            <div v-if="!mini" class="ma-2 pt-1 fw500">Logout</div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template> -->
  </section>

  <!-- </v-navigation-drawer> -->
</template>

<script>
import { mapActions, mapState } from 'vuex'

import Avatar from '../components/main/Avatar.vue'
import Nav from '../components/main/Nav.vue'
import SuperNav from '../components/main/SuperNav.vue'
import TeacherNav from '../components/main/TeacherNav.vue'
import UserNav from '../components/user/main/UserNav.vue'

export default {
  props: ['user', 'drawer', 'role', 'lists', 'mini'],
  components: {
    Avatar,
    Nav,
    SuperNav,
    TeacherNav,
    UserNav,
  },
  data: () => ({
    overlap: false
  }), 
  computed: {
    ...mapState({
      drawerUser: (state) => state.drawer
    })
  },
  // created() {
  //   this.$route.meta.collapse ? this.mini=true : this.mini=false
  // },
  methods: {
    ...mapActions(['logoutAction']),

    logout() {
      this.logoutAction()
    }
  },
  watch: {

  }
}
</script>
