<template>
  <div class="mt-10">
    <v-list dense class="drawer p-0" v-for="(list, i) in lists" :key="i">
      <v-subheader v-if="list.subheader.length > 0" class="caption fw500 text-uppercase px-5 secondary-4--text">{{ list.subheader }}</v-subheader>
      <section v-for="(item, i) in list.lists" :key="i">
        <v-list-group
          v-if="item.title == 'My Courses'"
          no-action
          :color="customization.hasOwnProperty('navigation_text_icon_color') ? customization.navigation_text_icon_color : 'primary'"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="roboto f14 d-flex align-center" >
                <v-icon class="px-2" size="20" :color="$route.meta.parent === item.route ? 'navigation_text_icon_color' : 'navigation_link_inactive_color'"> {{item.icon}} </v-icon>
                <div class="px-2" :class="$route.meta.parent === item.route ? 'navigation_text_icon_color--text' : 'navigation_link_inactive_color--text'"> {{ item.title }} </div>
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item class="roboto f12"  
            :to="{name: 'Instructor Published Course'}" 
            :style="$route.path === '/instructor/my-courses/published' && { background: getlinkBackgroundColor, color: getlinkColor, borderRight: '5px solid'}"
            :class="$route.path !== '/instructor/my-courses/published' && 'navigation_link_inactive_color--text'">
            <v-list-item-title
              :style="$route.path === '/instructor/my-courses/published' && { color: getlinktextColor}">Published</v-list-item-title>
          </v-list-item>
          <v-list-item class="roboto f12" 
            :to="{name: 'Instructor Unpublished Course'}" 
            :style="$route.path === '/instructor/my-courses/unpublished' && { background: getlinkBackgroundColor, color: getlinkColor, borderRight: '5px solid'}"
            :class="$route.path !== '/instructor/my-courses/unpublished' && 'navigation_link_inactive_color--text'">
            <v-list-item-title
              :style="$route.path === '/instructor/my-courses/unpublished' && { color: getlinktextColor}">Unpublished</v-list-item-title>
          </v-list-item>
          <v-list-item class="roboto f12" 
            :to="{name: 'Instructor Drafts Course'}" 
            :style="$route.path === '/instructor/my-courses/drafts' && { background: getlinkBackgroundColor, color: getlinkColor, borderRight: '5px solid'}"
            :class="$route.path !== '/instructor/my-courses/drafts' && 'navigation_link_inactive_color--text'">
            <v-list-item-title :style="$route.path === '/instructor/my-courses/drafts' && { color: getlinktextColor}">Drafts</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item 
          v-else-if="item.title == 'Assessment' || item.title == 'Grading'"
          :to="{ name: item.route }"
          class="px-5 li" 
          :style="$route.meta.parent === `Instructor ${item.title}` ? { background: getlinkBackgroundColor, color: getlinkColor, borderRight: '5px solid'} : ''">
          <v-list-item-content>
            <v-list-item-title class="roboto f14 d-flex align-center " :class="$route.meta.parent === `Instructor ${item.title}` ? 'navigation_text_icon_color--text'  : $route.meta.parent === `Instructor ${item.title}` ? 'navigation_text_icon_color--text' : 'navigation_link_inactive_color--text'">
              <v-icon v-if="mini" class="px-1" size="20" :color="$route.meta.parent === `Instructor ${item.title}` ? 'navigation_text_icon_color' : $route.meta.parent === `Instructor ${item.title}` ? 'navigation_text_icon_color' : 'navigation_link_inactive_color'">{{item.icon}}</v-icon>
              <v-icon v-else class="px-1" size="20" :color="$route.meta.parent === `Instructor ${item.title}` ? 'navigation_text_icon_color' : $route.meta.parent === `Instructor ${item.title}` ? 'navigation_text_icon_color' : 'navigation_link_inactive_color'">{{item.icon}}</v-icon>
              <div v-if="!mini" class="mx-3 fw500">{{item.title}}</div>
              
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item 
          v-else
          :to="{ name: item.route }"
          class="px-5 li" 
          :style="$route.name === item.route ? { background: getlinkBackgroundColor, color: getlinkColor, borderRight: '5px solid'} : ''">
          <v-list-item-content>
            <v-list-item-title class="roboto f14 d-flex align-center " :class="$route.name === item.route ? 'navigation_text_icon_color--text'  : $route.meta.parent === item.route ? 'navigation_text_icon_color--text' : 'navigation_link_inactive_color--text'">
              <v-icon v-if="mini" size="20" class="px-1" :color="$route.meta.parent == item.route ? 'navigation_text_icon_color' : $route.name === item.route ? 'navigation_text_icon_color' : 'navigation_link_inactive_color'">{{item.icon}}</v-icon>
              <v-icon v-else size="20" class="px-1" :color="$route.meta.parent == item.route ? 'navigation_text_icon_color' : $route.name === item.route ? 'navigation_text_icon_color' : 'navigation_link_inactive_color'">{{item.icon}}</v-icon>
              <div v-if="!mini" class="mx-3 fw500">{{item.title}}</div>
              
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </section>
    </v-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['lists', 'mini'],
  computed: {
    ...mapState({
      customization: (state) => state.customization
    }),

    getlinktextColor(){
			return this.customization.hasOwnProperty('navigation_text_icon_color') ? this.customization.navigation_text_icon_color ? this.customization.navigation_text_icon_color : '#B52210' : '#B52210'
		},
    
    getlinkColor(){
      return this.customization.hasOwnProperty('navigation_link_color') ? this.customization.navigation_link_color ? this.customization.navigation_link_color : '#B52210' : '#B52210'
    },

    getlinkBackgroundColor(){
      return this.customization.hasOwnProperty('navigation_link_color') ? this.customization.navigation_link_color ? this.customization.navigation_link_color.concat('33') : '#B5221033' : '#B5221033'
    }
  }
}
</script>