import { render, staticRenderFns } from "./StartTourDialog.vue?vue&type=template&id=1d5871e1&scoped=true&"
import script from "./StartTourDialog.vue?vue&type=script&lang=js&"
export * from "./StartTourDialog.vue?vue&type=script&lang=js&"
import style0 from "./StartTourDialog.vue?vue&type=style&index=0&id=1d5871e1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d5871e1",
  null
  
)

export default component.exports